#facets {
  width: 100%;

  #facet-wrapper {
    border: 1px solid #000;
    padding: 10px;
    margin-right: 20px;

    & h2,
    h3 {
      font-size: 20px;
      font-family: "Source Sans Pro", sans-serif !important;
    }

    // ErrorAlert styling
    .dates-error-alert {
      margin-bottom: 20px;
      max-width: 40em;

      .usa-alert-body {
        padding-left: 3.5em;
      }

    }

    // Error date input styling
    .input-with-error {
      .react-date-picker__wrapper {
        border: 3px solid $error;
        background-color: $error-transparent;
        .react-date-picker__inputGroup {
          padding: 0 20px;
        }
      }
    }

    // Unset default max-width as well as set width in order to fix the calendar cut-off issue
    .react-date-picker {
      width: 100%;
      .react-date-picker__wrapper {
        width: 200px;
      }
    }
  }
}

.cancel {
  cursor: pointer;
  svg {
    transform: translateY(-3px);
  }
}


.date-picker-icon-container{
  width: 100%;
  position: relative;
  display: inline-block;

}

@-moz-document url-prefix() {
  input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month,
  input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day,
  input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
    -moz-appearance: textfield;
  }
}

// Make calendar a little more responsive on mobile view
@media only screen and (max-width: 576px) {
  .react-date-picker__calendar {
    width: 200% !important;
  }
}
