// Grid container
.usa-grid,
.usa-grid-full {
  @include outer-container($site-max-width);
}

.usa-grid {
  @include padding(null $site-margins-mobile);

  @include media($medium-screen) {
    @include padding(null $site-margins);
  }
}

.usa-grid-full {
  padding: 0;
}

// Grid items
@include media($medium) {
  .usa-width-one-whole {
    @include span-columns(6);
  }

  .usa-width-one-half {
    @include span-columns(3);
  }

  .usa-width-one-third {
    @include span-columns(2);
  }

  .usa-width-two-thirds {
    @include span-columns(4);
  }

  .usa-width-one-fourth {
    @include span-columns(3);

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .usa-width-three-fourths {
    @include span-columns(6);
  }

  .usa-width-one-sixth {
    @include span-columns(2);

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .usa-width-five-sixths {
    @include span-columns(4);
  }

  .usa-width-one-twelfth {
    @include span-columns(2);

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .usa-width-five-twelfths {
    @include span-columns(2);
  }

  .usa-width-seven-twelfths {
    @include span-columns(4);
  }
}

@include media($large) {
  .usa-width-one-whole {
    @include span-columns(12);
  }

  .usa-width-one-half {
    @include span-columns(6);
  }

  .usa-width-one-third {
    @include span-columns(4);
  }

  .usa-width-two-thirds {
    @include span-columns(8);
  }

  .usa-width-one-fourth {
    @include span-columns(3);

    &:nth-child(2n) {
      @include span-columns(3);
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  .usa-width-three-fourths {
    @include span-columns(9);
  }

  .usa-width-one-sixth {
    @include span-columns(2);

    &:nth-child(3n) {
      @include span-columns(2);
    }

    &:nth-child(6n) {
      margin-right: 0;
    }
  }

  .usa-width-five-sixths {
    @include span-columns(10);
  }

  .usa-width-one-twelfth {
    @include span-columns(1);

    &:nth-child(3n) {
      @include span-columns(1);
    }

    &:nth-child(12n) {
      margin-right: 0;
    }
  }

  .usa-width-five-twelfths {
    @include span-columns(5);
  }

  .usa-width-seven-twelfths {
    @include span-columns(7);
  }
}

// Specifies end of a row.
// Required if grid-box contains multiple rows.
// Required if browser does not support :last-child
.usa-end-row {
  @include omega();
}

// Grid Offsets
.usa-offset-one-twelfth {
  @include shift(1 of 12);
}

.usa-offset-one-sixth {
  @include shift(2 of 12);
}

.usa-offset-one-fourth {
  @include shift(3 of 12);
}

.usa-offset-one-third {
  @include shift(4 of 12);
}

.usa-offset-five-twelfths {
  @include shift(5 of 12);
}

.usa-offset-one-half {
  @include shift(6 of 12);
}

.usa-offset-seven-twelfths {
  @include shift(7 of 12);
}

.usa-offset-two-thirds {
  @include shift(8 of 12);
}

.usa-offset-three-fourths {
  @include shift(9 of 12);
}

.usa-offset-five-sixths {
  @include shift(10 of 12);
}

.usa-offset-eleven-twelfths {
  @include shift(11 of 12);
}
