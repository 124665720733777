.all-results {
  flex: 4;
}

.single-result {
  word-break: break-word;

  & h4 {
    font-family: "Source Sans Pro",sans-serif;
    font-size: 17px;
  }
}

.pills {
  padding: 10px 0px;
}
.meta-pill_year {
  margin-right: 10px;
}
.meta-pill {
  background-color: #f1f1f1;
  display: inline-flex;
  border-radius: 14.5px;
  text-align: center;
  margin-right: 20px;
  padding: 0px 10px;
}

em {
  background-color: #FFFF00;
  font-weight: bold;
  font-style: normal;
}

.case-number-no-results-text{
  margin-top:5rem;
}