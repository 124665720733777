svg {
  width: 20px;
  height: 20px;
}

.date-input-clear-icon{
  background-image: url('/modules/custom/bg_azure_search/theme/build/img/close-primary.png');
  background-image: url('/modules/custom/bg_azure_search/theme/build/img/close-primary.svg');
  background-repeat: no-repeat;
  background-position: center;
  height:35px;
  width:35px;
  position: absolute;
  bottom:21px;
  right: 15%;
  background-color: transparent!important;
  cursor: pointer;
  &:hover{
    background-color: transparent!important;
  }
}
//can't use position: static bc of ie (calendar won't toggle for datepicker) or absolute bc of all other browsers (subsequent elements will jump up)
.react-date-picker.react-date-picker--open.react-date-picker--enabled, .react-date-picker--open {
  position: relative!important;
  }
