#pager {
    display: inline-flex;
    margin-top: 15px;
}
#pager > div > input {
    background-color: #f1f1f1;
    color: #212121;
    padding: 0.625rem 0.7em;
    margin: 0;
    border-radius: 0;
    border: #cecece 1px solid;
    border-right: none;
    font-size: 1.0625rem;
    height: 2.75rem;
}
#pager > div > input.current {
    background-color: #0071bc;
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
}
#pager .continuation {
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    border-left: none;
    border-right: none;
}
#pager .pagerFirst {
    border-right: #cecece 1px solid;
}
#pager .pagerNext {
    border-right: #cecece 1px solid;
}
#pager .pagerRight {
    border-right: #cecece 1px solid;
}
