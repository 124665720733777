#case-search-submit {
    background-color: #0071bc;
    margin-left: 10px;
    margin-top: 28px;
    min-width: 85px;
}
.groupLabel {
    width: fit-content;
    width: 100%;
}
.groupLabel label {
    width: fit-content;
    margin-top: 0;
}
svg:not(:root) {
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
}
[type=radio]:checked + label::before {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #0071bc;
}
[type=checkbox]:checked + label::before, [type=radio]:checked + label::before {
    background-color: #0071bc;
}

#root [type=checkbox]:checked + label::before,
#root [type=checkbox]:checked:disabled + label::before {
    background-image: url('/modules/custom/bg_azure_search/theme/build/img/correct8.svg');
    background-position: 50%;
    background-repeat: no-repeat;
}
[type=checkbox]:checked + label::before {
    background-color: #0071bc;
    box-shadow: 0 0 0 1px #0071bc;
}
#clear-filters {
    background-color: #0071bc;
}
.SearchResults-main #clear-filters {
    background-color: #0071bc;
}
.Collapsible label {
    padding-left: 32px;
    text-indent: -32px;
}
.help-link {
    float: right;
    text-decoration: none;
    font-size: 0.8em;
}
.help-link:hover {
    text-decoration: underline;
}
.help-circle {
    float:right;
}
#root a {
    color: #0071bc;
}
#root .single-result a:visited {
    color: #4c2c92;
}
#keyword-search-submit {
    margin: 0;
    width: fit-content;
    background-color: #0071bc;
}
.SearchResults-main #keyword-search-submit {
    background-color: #0071bc;
}
#keyword-search {
    margin-right: 15px;
}
#submit-date-filter, #clear-date-filter {
    background-color: #0071bc;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
}

.SearchResults-main #submit-date-filter, #clear-date-filter {
    background-color: #0071bc;
}

.react-calendar__century-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__year-view .react-calendar__tile {
    padding: 15px 0 !important;
}

.react-calendar__year-view > .react-calendar__tile {
    padding: 15px 0 !important;
}

.react-calendar__tile abbr {
    cursor: pointer;
}

#submit-date-filter:disabled,
#clear-date-filter:disabled {
    background-color: #d6d7d9;
}

.SearchResults-main #submit-date-filter:disabled,
.SearchResults-main #clear-date-filter:disabled {
    background-color: #d6d7d9;
}

.react-calendar__month-view__days > button {
    background-color: #fff;
    border-radius: 0;
    color: #0071bc;
}

.cd-main-content .SearchResults-main .react-calendar__month-view__days > button {
    background-color: #fff;
    color: #0071bc;
}

.react-calendar__navigation > button {
    border-radius: 0;
    color: #0071bc;
}

.SearchResults-main .react-calendar__navigation > button {
    color: #0071bc;
}

button.react-calendar__tile--active {
    color: #fff;
    background-color: #0071bc;
}

.SearchResults-main button.react-calendar__tile--active {
    color: #fff;
    background-color: #0071bc;
}

.react-calendar__year-view__months > button {
    background-color: #fff;
    color: #0071bc;
}

.SearchResults-main .react-calendar__year-view__months > button {
    background-color: #fff;
    color: #0071bc;
}
button.react-calendar__tile--hasActive {
    color: #fff;
    background-color: #0071bc;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    color: #0071bc;
}

.cd-main-content .SearchResults-main .react-calendar__navigation button:enabled:hover,
.cd-main-content .SearchResults-main .react-calendar__navigation button:enabled:focus {
    color: #0071bc;
}

.react-calendar__month-view__days button:enabled:hover,
.react-calendar__month-view__days button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__decade-view__years > button {
    background-color: #fff;
    color: #0071bc;
}
.SearchResults-main .react-calendar__decade-view__years > button {
    background-color: #fff;
    color: #0071bc;
}
.react-calendar__month-view__weekdays {
    background-color: #fff;
}
.SearchResults-main .react-calendar__month-view__weekdays {
    background-color: #fff;
}
button.react-calendar__tile--hasActive {
    color: #fff;
    background-color: #0071bc;
}
.SearchResults-main button.react-calendar__tile--hasActive {
    color: #fff;
    background-color: #0071bc;
}
.react-date-picker__calendar {
    top: 47px!important;
    width: 260px!important;
}
.Collapsible__contentInner > .date-range {
    overflow: paged-x;
    overflow: -webkit-paged-x;
    overflow: -webkip-paged-y;
}
.react-date-picker--open {
    position: absolute;
}
#clear-filters-button {
    padding-top: 38px;
}

#clear-filters-button input {
    height: 2.75rem;
}

.upper-date {
    padding-top: 11px;
}
.usa-accordion-content {
    background-color: transparent;
}
.react-date-picker__wrapper {
    background-color: #fff;
}
.react-calendar__navigation > .react-calendar__navigation__label {
    margin-left: -8%;
}
.cd-main-content .SearchResults-main .react-calendar__navigation > .react-calendar__navigation__label {
    margin-left: -8%;
}
.react-date-picker__inputGroup__year {
    width: 50px!important;
}
#root > div > div > div > div.row--grid.background--gray {
    display: block !important;
    padding: 2em 10%!important;
}
