body {
  font-family: Source Sans Pro,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
}

h3 {
  font-size: 40px;
  margin: 0 0 30px 0;
  font-family: Merriweather,Georgia,Cambria,Times New Roman,Times,serif;
}

a {
  color: $blue--main;
}

display {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 1.3em;
}

// h1, h2, h3, h4, h5 {
//   font-family: 'Source Sans Pro', sans-serif;
//   font-weight: 700;
//   line-height: 1.3em;
// }

h1, h3, h4, h5 {
  margin-bottom: 10px;
}

h1
{
  font-size: 44px;
}

h2
{
  font-size: 34px;
  margin-bottom: 20px;
}

h3
{
  font-size: 24px;
}

h4
{
  font-size: 19px;
}

h5
{
  font-size: 15px;
}

h6
{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.3em;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.lead {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5em;
  margin-bottom: 40px;
}

body, p {
  font-weight: 400;
  font-size: 17px;
  line-height: 1.5em;
  margin-bottom: 25px;
}

em, i {
  font-style: italic;
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 400px;
}


// .font-secondary{
//   font: $font-secondary;
// }