#search-form {
  display: -ms-grid;
  -ms-grid-columns: minmax(1em, 1fr) 80% minmax(1em, 1fr);
  .SearchForm-left {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: left;
  }
  .SearchForm-main {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: main;
  }
  .SearchForm-right {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: right;
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
  color:black;
}
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
  width: auto;
}

.usa-button:disabled,
[type="button"]:disabled {
  background-color: #d6d7d9 !important;
  color: black;
}

.cd-main-content .SearchResults-main .usa-button:disabled,
.cd-main-content .SearchResults-main [type="button"]:disabled {
  background-color: #d6d7d9 !important;
  color: black;
}

.btn-link {
  min-width: 90px;
  -webkit-font-smoothing: unset;
  background-color: transparent;
  color: #0071bc;
  font-weight: 400 !important;
  font-size: 17px;
  padding: 0;
  width: unset;
  min-width: unset;
  text-decoration: underline;
  margin-left: 5px;
  margin-top: 28px;

  &:hover {
    background-color: transparent;
    border-bottom: 0;
    color: #0071bc;
    text-decoration: underline;
  }
}

// Style Search Information link on medium device width
@media (max-width: 768px) {
  #search-form {
    & fieldset.usa-fieldset-inputs {
      display: flex;
      flex-direction: column;
      & a.search-instructions-link {
        margin-bottom: 20px;
        & svg {
          margin-left: 0;
          margin-right: 9px;
        }
      }
    }
  }
}
