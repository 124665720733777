.all-results {
    width: 90%;
}
.search-container .modifyDisplay {
    display: none;
}
.row--grid {
    grid-template-columns: [full-start] minmax(1em, 1fr) [main-start] 80% [main-end] minmax(1em, 1fr) [full-end];
}
.mobileOverlay {
    height: 100%;
    display: none;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #f1f1f1;
    overflow-x: hidden;
    transition: 0.5s;
}
.mobileOverlay svg {
    vertical-align: middle;
    margin-right: 5px;
}
.mobileFilter {
    position: relative;
    width: 100%;
    margin-top: 30px;
    padding-right: 10%;
    padding-left: 10%;
}


/* Close and Clear Buttons */
.closeClearBtns {
    display: flex;
    padding: 20px;
    justify-content: space-between;
}

/* Resetting USWDS default button styles */
.closeClearBtns .mobileClose,
.closeClearBtns .mobileClear
{
    text-decoration: none;
    margin:0;
    padding: 0;
    background-color: transparent;
    width: auto;
    color: #0070ba;
    font-weight: 700;
}

.closeClearBtns .mobileClose:hover,
.closeClearBtns .mobileClear:hover {
    background-color: transparent;
    border-bottom: 0;
    color: #0070ba;
    text-decoration: none;
}

.closeClearBtns .mobileClear {
    margin-right: 13px;
    margin-top: -3px;
}

.closeClearBtns .mobileClose > svg {
    margin-bottom: 3px;
    margin-right: 7px;
    margin-top: auto;
    margin-bottom: auto;
}



.mobile-filters {
    display: none;
}
.mobile-help {
    border-radius: 6px;
    padding: 4px 20%;
    margin-left: 20%;
    color: #0771b9;
    background-color: #fff;
    border: 2px solid #0071bc;
    text-decoration: none;
}
.mobile-help:visited {
    color: #0071bc;
    text-decoration: none;
}
input, select {
    max-width: 430px;
}
@media (min-width: 768px) and (max-width: 900px) {
    .all-results {
        width: 60%;
    }
    .modifyDisplay.Display {
        padding-right: 40px;
    }
}
/*Responsive Styles*/
@media (max-width: 767px) {
    #case-number-search .groupLabel {
        width: 100%;
    }
    #case-number-search {
        flex-direction: column;
    }
    #case-number-search .margin-lr-15 {
        margin: 0 0;
    }
    .usa-accordion h2 {
        font-size: 18px;
        padding-left: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: fit-content;
    }
    .mobile-filters {
        display: inherit;
        width: 100%;
        color: #0071bc;
        background-color: #fff;
        border: 2px solid #0071bc;
        margin-top: 10px;
    }
    .mobile-filters > span.Collapsible__trigger {

    }
    #result-counter {
        padding-top: 10px;
    }
    .d-inline {
        display: inline-flex;
        flex-flow: column;
    }
    .search-container h3 {
        font-size: 30px;
    }
    .padding-all-30 {
        padding: 0 0 10px 0;
    }
    .padding-all-30 > h3 {
        padding-top: 15px;
    }
    .margin-r-30 {
        margin-right: 0;
    }
    #case-number-search #case-search-submit {
        width: 100%;
        margin: 32px 0 15px 0 !important;
    }
    #facets {
        display: none;
    }
    .all-results {
        width: 80%;
    }
    .all-results .modifyDisplay {
        display: none;
    }
    .search-container .modifyDisplay {
        display: flex;
        float: unset;
        padding: 10px 0 0 0;
    }
    .search-container .modifyDisplay select {
        width: 87.5%;
        max-width: unset;
    }
    .search-container .modifyDisplay p {
        width: 70px;
        margin-top: 12px;
    }
    #pager > div > input {
        padding: 0 .4rem;
    }
}
@media (max-width: 350px) {
    .continuation {
        font-size: 12px;
    }
}
@media (max-width: 365px) {
    .usa-accordion h2 {
        font-size: 0.8em;
    }
}
@media (max-width: 330px) {
    #pager {
        margin-left: -3%;
    }
}
