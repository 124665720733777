.facetTooltip {
  display: inline-block;
  text-indent: 0;
  .tooltiptext {
    margin-left: 20px;
    text-indent: 0 !important;
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    padding: 5px 15px;
    position: absolute;
    z-index: 1;
    margin-top: -5px;
    width: max-content;
    word-wrap: break-word;
    max-width: 410px;
    &::after {
      content: " ";
      position: absolute;
      // top: 50%;
      top: 0;
      right: 100%;
      // margin-top: -5px;
      margin-top: 0;
      // border-width: 5px;
      border-width: 17.7px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }
  }
  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}

@media (max-width: 576px) {
  // Prevent tooltips on mobile view
  .facetTooltip {
    &:hover {
      .tooltiptext {
        visibility: hidden;
      }
    }
  }
}
