/*styling for the display modifiers, sort by and # to display*/
.modifyDisplay {
    float: right;
    padding-left: 15px;
    padding-bottom: 15px;
}
.modifyDisplay > p {
    margin-top: 0;
    margin-bottom: 10px;
}
.meta-pill {
    background-color: #d6d7d9;
}