button.react-date-picker__calendar-button.react-date-picker__button{
    background-color: transparent !important;
    padding-left: 5px;
    padding-right: 5px;
}

#case-search-clear.btn-link,
#case-search-clear.btn-link:hover {
    background-color: transparent;
    color: #0071bc;
    text-decoration: underline;
    margin-top: 28px;
    min-width: 135px;
    min-width: fit-content;
}

#case-search-submit.btn-lg.btn-primary.search-button.usa-button.usa-search-big,
#keyword-search-submit.btn-lg.btn-primary.search-button.usa-button.usa-search-big {
    min-width: 90px;
    min-width: fit-content;
}