.SearchResults {
  display: -ms-grid;
  -ms-grid-columns: minmax(1em, 1fr) 80% minmax(1em, 1fr);

  .SearchResults-left {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: left;
  }
  .SearchResults-main {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: main;
    .usa-alert--info {
      .usa-alert-body {
        padding-left: 3.5em;
      }
    }
  }
  .SearchResults-right {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: right;
  }
}

#modify-display-results-container {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  & .modifyDisplay {
    float: unset;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #modify-display-results-container {
    select {
      background-image: none,url(../theme/vendor/uswds/img/arrow-both.svg),url(../theme/vendor/uswds/img/arrow-both.png);
    }
  }

}
