// Header variables ---------- //

$height-nav-secondary: 5rem;
$width-nav-sidebar:    250px;

// z-indexes - TODO refactor to a site-wide z-index management approach
$z-index-header:  7000;
$z-index-overlay: 8000;
$z-index-nav:     9000;

// Header ------------- //

.usa-header {
  width: 100%;
  z-index: $z-index-header;

  @include media($nav-width) {
    border-bottom: 1px solid $color-gray-lighter;
  }

  a {
    border-bottom: none;
  }

  .usa-search {
    @include media($nav-width) {
      float: right;
      max-width: 21.5rem;
    }
  }
}

.usa-logo {
  float: left;
  line-height: 4rem;
  margin-left: 1.5rem;

  @include media($nav-width) {
    line-height: 7.5rem;
    margin-left: 0;
  }

  a {
    color: $color-base;
    text-decoration: none;
  }

  br {
    display: none;

    @include media($nav-width) {
      display: block;
    }
  }
}

.usa-logo-text {
  display: block;
  font-size: $h5-font-size;
  font-style: normal;
  font-weight: $font-bold;
  margin: 0;

  @include media($nav-width) {
    display: block;
    font-size: 2.4rem;
    line-height: $heading-line-height;
  }
}

.usa-menu-btn {
  /* stylelint-disable declaration-block-no-ignored-properties */
  @include button-unstyled;
  background-color: $color-primary;
  color: $color-white;
  display: inline;
  float: right;
  font-size: $h6-font-size;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 5.8rem;
  /* stylelint-enable */

  @include media($nav-width) {
    display: none;
  }

  &:hover {
    color: $color-white;
    background-color: $color-primary-darker;
  }

  &:visited {
    color: $color-white;
  }
}

.usa-overlay {
  @include position(fixed, 0);
  background: $color-black;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  z-index: $z-index-overlay;

  &.is-visible {
    opacity: 0.1;
    visibility: visible;
  }
}

// Basic header ----------- //

.usa-header-basic {
  .usa-logo {
    @include media($nav-width) {
      bottom: 0;
      position: absolute;
    }
  }

  .usa-navbar {
    @include media($nav-width) {
      position: relative;
      width: 18%; // TODO: review this more
    }
  }
}

// Extended header ----------- //

.usa-header-extended {
  .usa-header {
    @include media($nav-width) {
      border-bottom: none;
    }
  }

  .usa-logo {
    @include media($nav-width) {
      @include margin(3rem null);
    }
  }

  em {
    @include media($nav-width) {
      font-size: 3.2rem;
      line-height: 1;
    }
  }

  .usa-navbar {
    @include media($nav-width) {
      @include outer-container($site-max-width);
      @include padding(null $site-margins);
      display: block;
      height: auto;
    }
  }

  .usa-nav {
    @include media($nav-width) {
      border-top: 1px solid $color-gray-lighter;
      float: none;
      padding: 0;
      width: 100%;
    }
  }

  .usa-nav-inner {
    @include media($nav-width) {
      @include outer-container($site-max-width);
      @include padding(null $site-margins null 1.5rem);
      position: relative;
    }
  }

  .usa-nav-primary {
    @include media($nav-width) {
      @include clearfix;
    }

    button {
      &[aria-expanded=false],
      &[aria-expanded=true] { /* stylelint-disable-line selector-no-qualifying-type */
        @include media($nav-width) { /* stylelint-disable-line selector-no-qualifying-type */
          background-position: right 1.5rem top 53%;
        }
      }
    }
  }

  .usa-nav-link {
    @include media($nav-width) {
      @include padding(1.8rem null);
    }
  }

  .usa-nav-submenu {
    .usa-grid-full {
      @include media($nav-width) {
        padding-left: 1.2rem;
      }
    }
  }

  .usa-megamenu {
    @include media($nav-width) {
      padding-left: $site-margins;
    }
  }

  // Temp code for v1 to force show the search at mobile, not dekstop
  /* stylelint-disable selector-no-id, declaration-no-important  */
  .usa-search {
    &[hidden] {
      display: block !important;

      @include media($nav-width) {
        display: none !important;
      }
    }
  }
  /* stylelint-enable */
}
