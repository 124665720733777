.Accordion > .Collapsible > .Collapsible__trigger {
    display: block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    font-style: normal;
    font-size: 17px;
    border-bottom: 1px solid #9a9a9a;
    margin-bottom:15px;
    padding-left: 15px;
    color: #202020;
    text-align: left;
    cursor: pointer;
}
.Accordion > .Collapsible > .Collapsible__trigger.is-disabled {
    color: #6d6d6d;
}

.Accordion > .Collapsible .Collapsible__trigger.is-disabled:after {
    content: url('/modules/custom/bg_azure_search/theme/build/img/minus.svg');
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    float: right;
    margin-right: 15px;
}

.Accordion > .Collapsible .Collapsible__trigger:after {
    color: #212121;
}
.Accordion > .Collapsible .Collapsible__trigger:after {
    content: url('/modules/custom/bg_azure_search/theme/build/img/plus.svg');
    color: #0071bc;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    float: right;
    margin-right: 15px;
}

.Accordion > .Collapsible > .is-open:after {
    content: url('/modules/custom/bg_azure_search/theme/build/img/minus.svg');
    width: 20px;
    height: 20px;
}
.facetTooltip {
    margin-left: 15px;
    margin-top: 1rem;
}

.SearchResults-main .facetTooltip {
    margin-left: 0;
    margin-top: 0;
}

.is-closed + .Collapsible__contentOuter {
    visibility: hidden;
}

.is-open + .Collapsible__contentOuter {
    visibility: visible;
}

.cd-main-content button.react-date-picker__calendar-button {
    background-color: transparent;
}

.cd-main-content .SearchResults-main > fieldset {
    flex: 2 0;
}

.cd-main-content .SearchResults-main button.react-date-picker__calendar-button {
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
}

.cd-main-content .SearchResults-main .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    padding-left: 20px;
    padding-right: 20px;
}

.cd-main-content .SearchResults-main .react-calendar__tile:disabled {
    border-radius: 0 !important;
}

.cd-main-content .SearchResults-main .react-calendar button {
    margin: 0;
    border: 0;
    border-radius: 0;
    outline: none;
}

#submit-date-filter, #clear-date-filter { height: 3rem; }
