.usa-banner {
  background-color: $color-gray-lightest;
  padding-bottom: 0.7rem;

  @include media($small-screen) {
    font-size: $h6-font-size;
    padding-bottom: 0;
  }
}

.usa-banner-content {
  @include padding(null $site-margins-mobile 3px $site-margins-mobile);
  background-color: transparent;
  font-size: $h5-font-size;
  padding-top: 2rem;
  width: 100%;

  @include media($medium-screen) {
    padding-bottom: 2.3rem;
    padding-top: 4rem;
  }

  @include media($nav-width) {
    @include padding(null $site-margins);
  }

  p {
    &:first-child {
      margin-top: 1rem;

      @include media($medium-screen) {
        margin-top: 0;
      }
    }
  }
}

.usa-banner-inner {
  @include outer-container($site-max-width);
  @include padding(null $site-margins-mobile);

  @include media($nav-width) {
    @include padding(null $site-margins);
  }
}

.usa-banner-header {
  @include padding(0.55rem null);
  font-size: 1.2rem;
  font-weight: $font-normal;

  @include media($small-screen) {
    @include padding(0 null);
  }

  p {
    @include margin(0 null);
    display: inline;
    overflow: hidden;
    vertical-align: middle;

    @include media($small-screen) {
      @include margin(2px null);
      display: inline-block;
    }
  }

  img {
    float: left;
    margin-right: 1rem;
    margin-top: 1px;
    width: 2.4rem;

    @include media($small-screen) {
      margin-right: 0.7rem;
      width: 2rem;
    }
  }
}

.usa-banner-header-expanded {
  align-items: center;
  border-bottom: 1px solid $color-gray-light;
  display: flex;
  font-size: $h5-font-size;
  min-height: 5.6rem;
  padding-right: 3rem;

  @include media($small-screen) {
    border-bottom: none;
    display: block;
    font-size: 1.2rem;
    font-weight: $font-normal;
    min-height: 0;
    padding-right: 0;
  }

  > .usa-banner-inner {
    margin-left: 0;

    @include media($small-screen) {
      margin-left: auto;
    }
  }

  img {
    margin-right: 2.4rem;

    @include media($small-screen) {
      margin-right: 0.7rem;
    }
  }

  p {
    line-height: $heading-line-height;
    vertical-align: top;

    @include media($small-screen) {
      line-height: $base-line-height;
      vertical-align: middle;
    }
  }
}

.usa-banner-button {
  @include button-unstyled;
  @include padding(1.3rem null null 4.8rem);
  background-position-x: right;
  color: $color-primary;
  display: block;
  font-size: 1.2rem;
  height: 4.3rem;
  left: 0;
  position: absolute;
  text-decoration: underline;
  top: 0;
  width: 100%;

  @include media($small-screen) {
    @include padding(0 null null 0);
    /* stylelint-disable declaration-block-no-ignored-properties */
    display: inline;
    height: auto;
    margin-left: 3px;
    position: relative;
    vertical-align: middle;
    width: auto;
    /* stylelint-enable */
  }

  &:hover {
    color: $color-primary-darker;
    text-decoration: underline;
  }

  &[aria-expanded=false] {
    @include display-icon(angle-arrow-down-primary, after, 1rem, 0, hover);
    background-image: none;

    &::after {
      background-position-y: 1px;
    }
  }

  &[aria-expanded=true] {
    @include display-icon(close, after, 1.3rem, 0, no-hover);
    background-image: none;
    height: 5.6rem;

    @include media($small-screen) {
      @include display-icon(angle-arrow-up-primary, after, 1rem, 0, hover);
      height: initial;
      padding: 0;
      position: relative;

      &::after {
        background-position-y: 1px;
      }
    }

    &::after {
      position: absolute;
      right: 1.5rem;
      top: 2.15rem;

      @include media($small-screen) {
        position: static;
      }
    }

    .usa-banner-button-text {
      display: none;

      @include media($small-screen) {
        display: inline;
      }
    }
  }
}

.usa-banner-icon {
  width: 3.8rem;
}
