.row--grid {
  display: grid;
  grid-template-columns: 
    [full-start] minmax(1em, 1fr) 
    [main-start] 80% [main-end]
    minmax(1em, 1fr) [full-end];
}

.container-inner {
  grid-column: main;
}

/***
* ALIGNMENT
**/

.flex-container
{
  display: flex;
}

.flex-item-1
{
  flex:1;
  min-width: 0; // fix for Edge flex bug
}

.list-inline li
{
  display: inline-block;
  &:not(:last-child) {
    margin-right: 30px;
  }
}

.usa-grid
{
  max-width: 1200px!important;
}

.full-width
{
  max-width: 100%;
}

.d-inline
{
  display: inline;
}

.f-right
{
  float: right;
}
.container-primary
{
  max-width: 1200px;
  margin: 0 auto;
}
/**
* MARGIN
**/
.margin-lr-15
{
  margin: 0 15px;
}
.margin-r-30
{
  margin-right: 30px;
}
.margin-b-30
{
  margin-bottom: 30px;
}
.margin-tb-0
{
  margin-top:0;
  margin-bottom:0;
}


/**
* PADDING
**/
.padding-all-50
{
  padding: 50px;
}
.padding-all-30
{
  padding: 30px;
}
.padding-all-10
{
  padding: 10px;
}
.padding-lr-50
{
  padding: 0 50px;
}
.padding-lr-15
{
  padding: 0 15px;
}
.padding-l-30
{
  padding-left: 30px;
}
.padding-l-40
{
  padding-left: 40px;
}

@media screen and (max-width: 576px) {
  .hide-mobile {
    display: none;
  }
}