#search-form {
  border-bottom: 1px solid #5b616b;
  .usa-list {
    padding-left: 0;
    margin-left: 0;
  }
  .form-Small {
    max-width: 100%!important;
  }
}

.search-container h3 {
  font-size: 40px;
  margin: 0 0 30px 0;
}

#searchByKeyword, #collapseOne {
  position: relative;
  input, select, textarea {
    height: 2.6em;
    border: 1px solid #5b616b;
    border-radius: 0;
  }
  input.btn-link, input.usa-button {
    border: none;
  }
  input.usa-button {
    border-radius: 5px;
  }
  & .fa-search
  {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    margin-left: 1px;
    margin-right: 0;
  }
}

#collapseOne {
  select {
    padding: 0 0 0 .75em;
  }
}

.case-search-error-alert {
  margin-top: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-position: 1rem 1rem;
  background-size: 3.2rem;
  margin-bottom: 1rem;
  & .usa-alert-body {
    padding-left: 1.5rem;
    & h3 {
      font-size: 25px;
      margin: 0;
    }
  }
}

#clear-filters-button{
  margin-top: 25px;
  margin-bottom: 75px;
}
