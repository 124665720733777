$usa-form-width: 32rem;

[type=submit] {
  @include media($medium-screen) {
    margin-top: calc(3rem - .2em); // Label margin minus input bottom margin
  }
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.usa-form {
  @include media($medium-screen) {
    max-width: $usa-form-width;
  }
}

.usa-input-tiny {
  @include media($medium-screen) {
    max-width: 6rem;
  }
}

.usa-input-medium {
  @include media($medium-screen) {
    max-width: 12rem;
  }
}

.usa-form-note {
  float: right;
  font-family: $font-sans;
  font-size: $h5-font-size;
  margin: 0 0 1.5rem;

  + * {
    clear: both;
  }
}

.usa-form-large {
  @include media($medium-screen) {
    max-width: 46rem;
  }
}

.usa-input-grid {
  @include media($medium-screen) {
    padding-right: 5%;
  }
}

.usa-input-grid:last-of-type {
  @include media($medium-screen) {
    padding-right: 0;
  }
}

.usa-input-grid-small {
  @include media($medium-screen) {
    float: left;
    width: 35%;
  }

  input {
    margin-bottom: 3rem;
  }

  select {
    margin-bottom: 3rem;
  }
}

.usa-input-grid-medium {
  @include media($medium-screen) {
    float: left;
    width: 65%;
  }

  input {
    margin-bottom: 3rem;
  }

  select {
    margin-bottom: 3rem;
  }
}

.usa-input-grid-large {
  @include media($medium-screen) {
    float: left;
    width: 100%;
  }

  input {
    margin-bottom: 3rem;
  }

  select {
    margin-bottom: 3rem;
  }
}

.usa-additional_text {
  font-weight: normal;
}

// Reset password checklist

.usa-checklist {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    display: inline-block;
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 3em;
    text-indent: -2em;

    &::before {
      content: ' ';
      display: inline-block;
      height: .8em;
      margin-right: .2em;
      width: 1.8em;
    }
  }
}

.usa-checklist-checked {
  &::before {
    background-image: url('#{$image-path}/correct9.png');
    background-image: url('#{$image-path}/correct9.svg');
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
