.usa-label {
  background-color: $color-gray;
  border-radius: $label-border-radius;
  color: $color-white;
  font-size: $h5-font-size;
  margin-right: 0.5rem;
  padding: 0.1rem 0.7rem;
  text-transform: uppercase;

  &:only-of-type {
    margin-right: 0;
  }
}

.usa-label-big {
  @extend .usa-label;
  font-size: $base-font-size;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}
